@tailwind base;
@tailwind components;
@tailwind utilities;

img {
	pointer-events: none;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

h2 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111;
    border-bottom: 1px #AAA solid;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

h3 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #111;
    padding: 8px 0 8px 0;
}

ul {
    list-style-type: disc;
    padding-left: 2rem;
    padding-bottom: 1rem;
}

p {
    padding: 4px 0 12px 0;
}
